import "../styles/globals.css";
import { useEffect } from "react";

import { ApolloProvider } from "@apollo/client";
import { NoSsr } from "@mui/material";
import { LicenseInfo } from "@mui/x-license";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { NextPage } from "next";

import { ColumnLayout } from "shared/layouts/ColumnLayout";
import {
  handleRefreshMessage,
  isReactNativeWebview,
} from "shared/lib/reactNativeWebviewUtils";
import { AuthProvider } from "shared/providers/AuthProvider";
import { ColorModeProvider } from "shared/providers/ColorModeProvider";
import { FeatureFlagProvider } from "shared/providers/FeatureFlagProvider";
import { UnreadRequestsProvider } from "shared/providers/UnreadRequestsProvider";
import { SnackbarProvider } from "shared/toast/SnackbarProvider";

import PageHead from "../components/PageHead";
import client from "../lib/apollo-client";
import { ShopSetupProvider } from "../providers/ShopSetupProvider";

import type { AppProps } from "next/app";

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY);

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_X_LICENSE_KEY as string);

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const routesRequireLogo = ["/auth/signin", "/auth/invite"];

function MyApp({ Component, pageProps, router }: AppPropsWithLayout) {
  const disableAuth =
    router.asPath.startsWith("/auth/") ||
    router.asPath.startsWith("/onboarding") ||
    router.asPath.startsWith("/forgot-password") ||
    router.asPath.startsWith("/reset-password");

  const showColumnLayoutWithLogo = routesRequireLogo.some((route) =>
    router.asPath.includes(route)
  );

  useEffect(() => {
    if (isReactNativeWebview(window)) {
      window.addEventListener("message", (event: MessageEvent) =>
        handleRefreshMessage(event, client)
      );
    }
    return () => {
      window.removeEventListener("message", (event: MessageEvent) =>
        handleRefreshMessage(event, client)
      );
    };
  }, []);

  return (
    <NoSsr>
      <PageHead />
      <AuthProvider routeRequiresAuth={!disableAuth} loginPath="/auth/signin">
        <FeatureFlagProvider>
          <ApolloProvider client={client}>
            <Elements stripe={stripePromise}>
              <ColorModeProvider>
                <SnackbarProvider>
                  <UnreadRequestsProvider>
                    <ShopSetupProvider disableProvider={disableAuth}>
                      {showColumnLayoutWithLogo ? (
                        <ColumnLayout showLogo={showColumnLayoutWithLogo}>
                          <Component {...pageProps} />
                        </ColumnLayout>
                      ) : (
                        <Component {...pageProps} />
                      )}
                    </ShopSetupProvider>
                  </UnreadRequestsProvider>
                </SnackbarProvider>
              </ColorModeProvider>
            </Elements>
          </ApolloProvider>
        </FeatureFlagProvider>
      </AuthProvider>
      <Analytics />
      <SpeedInsights />
    </NoSsr>
  );
}

export default MyApp;
